
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Ethnic Disable Summary Report',
  components: {
    Datatable,
    SearchBar,
    Swal,
    Multiselect,
  },
  data() {
    return {
      api_url: '',
      load: false,
      loading: false,
      tranche: [] as any,
      optionsTranche: [] as any,
      optionsTPartner: [] as any,
      association: [] as any,
      btnCheck: false,
      tranche_id: [] as any,
      entity_id: [] as any,
      report_type: '' as any,
      showEthnicDisableReport: false,
      componentKey: 0,
      actionActive: false,
      ethnicDisableReport: [] as any,
      selectAll: {
        id: 'all',
        label: 'All',
      } as any,
      Totalenrollment: 0 as any,
      Totalassesment: 0 as any,
      Totalcertification: 0 as any,
      total_job_placement: 0 as any,
       Totalenrollment_female: 0 as any,
      Totalassesment_female: 0 as any,
      Totalcertification_female: 0 as any,
      total_job_placement_female: 0 as any,
      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
    };
  },
  async created() {
    await this.getTranche();
    await this.getAssociation();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.associationData);
      formData.set('type', this.report_type);

      let data = `${this.VUE_APP_API_URL}/api/report/ethnicdisablereportprint`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async exportTrainee() {
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.associationData);
      formData.set('type', this.report_type);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/ethnicdisablereportExcel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'EthnicDisable.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async downloadPdf() {
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.associationData);
      formData.set('type', this.report_type);
      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/ethnicdisablePdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    filterEntity(entity) {
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.association.shift();

        this.association.forEach((val, index) => {
          if (val.id != 'select') {
            this.entity_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.association = [];
        this.association.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.association = [];

        this.association = this.tempAssociation;
        this.association.unshift(this.selectObj);
        this.entity_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
          this.association.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async EthnicDisableSummaryReport() {
      if (this.tranche_id.length > 0 && this.report_type) {
        this.load = true;
        await ApiService.get(
          'report/ethnicDisableReport?tranche_id=' +
          this.tranche_id +
          '&entity_id=' +
          this.associationData +
          '&type=' +
          this.report_type
        )
          .then((response) => {
            this.ethnicDisableReport = response.data.data.coursedata;
            this.Totalenrollment = response.data.data.Totalenrollment;
            this.Totalcertification = response.data.data.Totalcertification;
            this.Totalassesment = response.data.data.Totalassesment;

            this.total_job_placement = response.data.data.total_job_placement;
             this.Totalenrollment_female = response.data.data.Totalenrollment_female;
             this.Totalassesment_female = response.data.data.Totalassesment_female;
              this.Totalcertification_female = response.data.data.Totalcertification_female;
            this.total_job_placement_female = response.data.data.total_job_placement_female;

            this.showEthnicDisableReport = true;
            this.componentKey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please Select Tranche and Report Type',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    async btnCheckStatus() {
      if (this.tranche_id && this.report_type) {
        this.btnCheck = true;
      } else {
        Swal.fire({
          title: 'Tranche and Training Type Need to be Select!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete!',
        });
      }
      // else popup
    },

    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          console.log(response.data.data);
          this.tranche = response.data.data;
          this.tranche.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
